
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Vue, Options } from "vue-class-component";
import PPOBPopupModal from "@/app/ui/components/ppob-popup-modal/index.vue";

@Options({
  components: {
    PPOBPopupModal
  }
})
export default class Dashboard extends Vue {
  // account type
  get dataProfile() {
    return AccountController.accountData;
  }
  get accountType() {
    return this.dataProfile.account_type;
  }
  get accountTypeDetail() {
    return this.dataProfile.account_type_detail.type;
  }

  ppobDialogModelValue = false;
  togglePPOBDialog(bool: boolean) {
    this.ppobDialogModelValue = bool;
  }
}
